.smallBackgroundTop
{
background-color: white;
width: 100%;
height: 2.5rem;
}

.adjustTopText
{
    padding-left: 1rem;
    padding-right: 1rem;
}
.smallBackgroundTopSticky
{
    background-color: white;
    width: 100%;
    height: 2.5rem;
    position: fixed;
    z-index: 99;
    top: 0;
}

.adjustTopText
{
    font-size: 1rem;
    text-align: center;
    align-items: center;
    padding-top: 0.5rem;
    color: #3A1F89;
}

@media screen and (max-width: 770px) {
    .adjustTopText
    {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 507px)
{
    .smallBackgroundTop
    {
        height: 3rem;
    }
}