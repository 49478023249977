@font-face {
    font-family: italicLight;
    src: url(fonts/Jost-ExtraLightItalic.ttf);
}

.background-video {
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 1);
    /* Set the background color to black with 50% opacity */
}

.reviewStyle
{
    border: none; width: 100%; height: 100%;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.8) blur(0px);
    /* Adjust the blur effect as needed */
}

.servicesBackground
{
    width: 100%;
    height: 50rem;
    
}

.pawBackground
{
    background-image: url("./images/pawprint.png");
    filter: brightness(0.5) blur(5px);
    top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
}

.serviceTextBackground
{
    z-index: 1;
    position:absolute;
    width: 100%;
    
}

.backgroundVid
{
    height: 50rem;
    width: 100%;
    

}

.backgroundofText
{
    position:absolute;
    height: 30rem;
    width: 100%;
}
.backgroundText
{
    margin-top: 17rem;
    color:white;
   justify-content: center;
   text-align: center;
    z-index: 5;
    position: relative;
    font-size: 3rem;
    margin-bottom: 2rem;
    
    
}

.backgroundText:after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    margin-left: 30%;
    /* This will center the border. */
    width: 40vw;
    /* Change this to whatever width you want. */
    padding-top: 0.4rem;
    /* This creates some space between the element and the border. */
    margin-top: 1.5rem;
    
    background-color: #3A1F89;
    /*background-image: linear-gradient(90deg, #1CB5E0, #0d0d77);*/
}

.homeButton
{
    background-color: #3A1F89;
    width: 10rem;
    height: 3rem;
    position: relative;
    border: 1px solid #3A1F89;
    z-index: 5;
    text-align: center;
    justify-content: center;
    border-radius: 2rem;
    font-size: 1rem;
    color: white;
    
    align-items: center;
    cursor: pointer;
    margin:auto;
    display: flex;

}

.aboutButton,.HomePhotoButton
{
    margin: 3rem 3rem 3rem 3rem;
    border: 1px solid white;
    justify-content: left;
    background-color: #3A1F89;
    width: 10rem;
    height: 3rem;
    border-radius: 2rem;
    font-size: 1rem;
    color: white;
    cursor: pointer;
}

.HomePhotoButton
{
    background-color: white;
    border: 1px solid #3A1F89;
    color: #3A1F89;
}

.quoteServices
{
    color:white;
    text-align: left;
    padding-left: 4rem;
    padding-top: 4rem;
    font-size: 3rem;
    font-family: italicLight;
}

.quoteServices:after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    margin-left: 0%;
    /* This will center the border. */
    width: 39vw;
    /* Change this to whatever width you want. */
    padding-top: 0.4rem;
    /* This creates some space between the element and the border. */
    margin-top: 1.5rem;

    background-color: #3A1F89;
    /*background-image: linear-gradient(90deg, #1CB5E0, #0d0d77);*/
}

.servicesText
{
    padding: 4rem 50% 0 3.2%;
    color: white;
    font-size: 1rem;
}

.dogPhoto
{
    position: absolute;
    margin: 11rem 2% 0 55%;
    height: 80%;
    width: 40%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.serviceRealBackground
{
    background-color: #3A1F89;
    height: 40rem;
    width: 100%;
    
}

.serviceRealTitle
{
    
    color: white;
    text-align: center;
    align-items: center;
    font-size: 2rem;
    padding-top: 7rem;
}

.serviceRealText, .serviceRealTextAlt
{
    color: white;
    text-align: center;
    align-items: center;
    font-size: 1.5rem;
    padding-top: 1rem;
}

.serviceRealTextAlt
{
    font-family: italicLight;
}
.serviceRealImage
{
    position:absolute;
    padding-top: 5rem;
    width: 13rem;
    height: 7rem;
    padding-left: 10%;
}

.serviceRealImageTwo
{
    position:absolute;
    padding-top: 5rem; 
    padding-left: 80%;
    width: 13rem;
    height: 7rem;
    
}

.contactBackground
{
    height: 65rem;
    width: 100%;
}

.contactTitle
{
    text-align: center;
    font-size: 2.5rem;
    color:#3A1F89;
    padding-top: 2rem;


}


.contactText
{
    color: #3A1F89;
    padding-top: 5rem;
    text-align: left;
    font-size: 1.5rem;
    padding-left: 10%;
}

.contactTextTwo {
    color: #3A1F89;
    padding-top: 3rem;
    text-align: left;
    font-size: 1.5rem;
    padding-left: 10%;
    font-family: italicLight;
}

.contactContainer
{
    margin-left: 5%;
    margin-right: 5%;

}

.contactWrapper
{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20%;
}

.contactBox
{
    height: 30rem;
    
}

.CoverWatermark {
    height: 3rem;
    background-color: white;
    width: 50%;
    position: absolute;
    z-index: 99;
    margin-top: 33.8rem;
}

.footerBackground
{
    background-color: #3A1F89;
    height: 35rem;
    width: 100%;

}

.footerText
{
    text-align: right;
    align-items: right;
    padding-top: 3rem;
    color: white;
    padding-right: 5%;
}

.footerTextTwo
{
    text-align: right;
    padding-top: 1rem;
    font-family: italicLight;
    color: white;
    padding-right: 5%;
}

.footerLogo
{
    height: 15rem;
    width: 15rem;
    
    padding: 0;
    margin: auto;
    align-items: center;
    display: flex;
    
}


.comingSoonBackground
{
    width: 100%;
    background: white;
    height: 50rem;
}

.comingSoonTitle
{
    padding-top: 4rem;
    text-align: center;
    align-items: center;
    color: #3A1F89;
    font-size: 2.5rem;
}

.aboutBackground
{
    background-color: white;
    height: 130rem;
    width: 100%;

}

.questionBackground
{
    height: 30rem;
}

.aboutTitle
{
    color: #3A1F89;
    padding: 5rem 0 0 10%;
}

.aboutText
{
    color: #3A1F89;
    padding: 5rem 60% 0 10%;
    
}


.andrewDog,.wishlistImg
{
    position:absolute;
    padding-top: 8rem;
    padding-left: 55%;
    width: 30rem;
    height: 40rem;

}

.andrewDog
{
    padding-top: 14rem;
}


.aboutWrapper
{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    
}

.EmployeePhoto 
{
    width: 30rem;
    height: 40rem;
    margin-right: 5rem;

    
}

.PhotoOf
{
    width: 30rem;
    height: 40rem;
}


.wishlistImg
{
    height: 30rem;
    width: 30rem;
}


.mapStyle
{
    
    padding-top: 5rem;
    
    border: none;
    width: 35rem;
    height: 38rem;
}

.scheduleBackground {
    width: 100%;
    background: white;
    height: 150rem;
}

.scheduleTitle {
    padding-top: 4rem;
    text-align: center;
    align-items: center;
    color: #3A1F89;
    font-size: 2.5rem;
}

.scheduleText,.PhotoText
{
    padding-top: 4rem;
    text-align: center;
    align-items: center;
    color: #3A1F89;
    font-size: 1.2rem;
    
}

.PhotoText
{
    padding-top: 2rem;
}

.payButton
{
    background-color: #3A1F89;
        width: 15rem;
        height: 3rem;
        position: relative;
        border: 1px solid #3A1F89;
        z-index: 5;
        text-align: center;
        justify-content: center;
        border-radius: 2rem;
        font-size: 1rem;
        color: white;
        text-decoration: none;
        align-items: center;
        cursor: pointer;
        margin: auto;
        display: flex;
        margin-top : 3rem;
}

.paySection
{
    
    
    
    height: 0rem;
    width: 100%;
}

.PhotoBackground
{
    width: 100%;
    height: 370rem;
}

.PhotoContainer
{
    margin-top: 10rem;
    margin-left: 5%;
    margin-right: 5%;
}

.questionsTitle
{
    text-align: center;
    padding-top: 3rem;
    color:#3A1F89;
    font-size: 2rem;
}

.questionsDescription
{
    text-align: center;
    color:#3A1F89;
    font-size: 1rem;
    padding-top: 1rem;
}

.PhotoWrapper
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    text-align: center;
   
}

.PhotoResize
{
    width: 400px;
    height: 335px;
}

.PhotoTitle
{
    font-size: 2.2rem;
    color:#3A1F89;
    text-align: center;
    padding-top: 3rem;
}


.serviceNewBackground
{
    width: 100%;
    height: 50rem;
    background-color: #3A1F89;
    padding-top: 10rem;
}

.serviceTitle
{
    
    font-size: 2.5rem;
    color: white;
    text-align: center;
}


.serviceContainer
{
    margin: 3rem;
    
}

.serviceWrapper
{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap: 1rem;

}

.serviceBox
{
    background-color: white;
    border-radius: 0.5rem;
    height: 30rem;
}

.serviceBox:first-child, .serviceBox:last-child
{
    background-color: #3A1F89;
}

.serviceBoxContainer
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.serviceBoxNumber
{
    font-size: 1.5rem;
    padding: 1rem;
    color: #3A1F89;
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
}

.serviceBoxTitle
{
    font-size: 1.5rem;
    color: #3A1F89;
    padding-right: 10%;
}

.serviceBoxDescription
{
    font-size: 1rem;
    padding: 1rem;
    color:#3A1F89;
}

.PawIcon
{
    color: white;
    font-size: 10rem;
}

.PawContainer
{
    display: flex;
    justify-content: center;
}

.HomeContainer
{
    width: 100%;
    height: 120rem;
    background-color: white;
}

.HomeWrapper
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    
}

.HomeBox
{
    height: 63rem;
    background-color: #3A1F89;
    
}

.HomeBoxBackground
{
    background-color: white;
}
.HomeBoxImage
{
    width: 100%;
    height: 63rem;
    
    background-repeat: no-repeat;
    background-position:bottom;

}

.HomeBoxTitleOne, .HomeBoxTitleTwo
{
    color: white;
    font-size: 3rem;
    text-align: left;
    padding-top: 10rem;
    padding-left: 3rem;
    padding-right: 5%;
}

.HomeBoxTextOne, .HomeBoxTextTwo
{
    color: white;
    font-size: 1.3rem;
    text-align: left;
    padding-left: 3rem;
    padding-right: 5%;
}

.HomeBoxTitleTwo,.HomeBoxTextTwo
{
    color: #3A1F89;

}

.FooterContainer
{
    margin: 0 5% 0 5%;
    padding-top: 2rem;
}

.FooterWrapper
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10%;
}

.FooterBox
{
    height: 20rem;
    
}

.FooterLogoText,.FooterLogoTextIcon, .FooterContactText
{
    font-size: 1.5rem;
    color: white;
    text-align: center;
}


.FooterLogoTextIcon
{
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
}

.TextDecoration
{
    text-decoration: none;
    color: white;
}
.FooterContactTitle
{
    padding-top: 4rem;
    color: white;
    font-size: 1.3rem;
    text-decoration: underline;
    text-align: center;
    padding-bottom: 1rem;
}

.adjustBorder
{
    margin-top: 10rem;
    
}




@media screen and (max-width: 1438px) {


    .PhotoWrapper
    {
        grid-template-columns: 1fr 1fr;
    }

    .PhotoBackground
    {
        height: 540rem;
    }

   .CoverWatermark
   {
    width: 45%;
   }
}

@media screen and (max-width: 1389px) {

    .servicesBackground
    {
        height: 95rem;
    }

    .dogPhoto
    {
        
        margin-left: 8%;
        margin-top: 52rem;
        width: 85%;
        height: 30rem;
    }

    .andrewDog,.wishlistImg
    {
        padding-top: 45rem;
        padding-left: 31%;
        padding-right: 30%;
        
    }

    .aboutText, .aboutTitle
    {
        padding-left: 10px;
        text-align: center;
        align-items: center;
        padding-right: 10px;
    }

    .aboutText
    {
        padding-top: 3rem;
        font-size: 0.9rem;
    }

    .aboutBackground
    {
        height: 200rem;
    }

    .aboutWrapper
    {
        margin-top: 50rem;
        flex-direction: column;
    }

    .EmployeePhoto
    {
        margin-top: 5rem;
        margin-right: 0rem;
    }
    .servicesText
    {
        padding-right: 0%;
    }

        .quoteServices:after {
            width: 60%;
            
        }

    .footerBackground
    {
        height: 80rem;
    }

    .FooterWrapper
    {
        grid-template-columns: 1fr;
    }
}





@media screen and (max-width: 1350px)
{

    .serviceRealBackground
    {
        height: 55rem;
    }
    .contactBackground
    {
        height: 100rem;
    }

    .contactWrapper
    {
        grid-template-columns: 1fr;
    }

    .mapStyle
    {
        margin-top: 5rem;
        
        width: 100%;
    }

    .contactText .contactTextTwo
    {
        text-align: center;
        align-items: center;
        padding-left: 0;
        padding-top : 0;
    }

    .serviceWrapper
    {
        grid-template-columns: 1fr;
    }


    .serviceBox:first-child
    {
        display: none;
    }

    .serviceBox:last-child
    {
        padding-top: 5rem;
    }

    .serviceNewBackground
    {
        height: 107rem;
        padding-top: 15rem;
    }

    .serviceBox
    {
      height: 15rem;
    }

    .CoverWatermark
    {
        width:  90%;
    }

}

@media screen and (max-width: 1250px) {
    
    .serviceRealImage, .serviceRealImageTwo
    {
        display:none;
    }
    .serviceRealTitle
    {
        padding-top: 3rem;
    }

    .andrewDog,.wishlistImg
    {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

}

@media screen and (max-width: 1200px) {

    .HomeBox:nth-of-type(2)
    {
        order: -1;
    }
    .HomeWrapper
    {
        grid-template-columns: 1fr;
       
    }

    .HomeContainer
    {
        height: 240rem;
    }
}

@media screen and (max-width: 1000px) {

    
}

@media screen and (max-width: 950px) {

    .PhotoWrapper
    {
        grid-template-columns: 1fr;
    }

    .PhotoBackground
    {
        height: 1040rem;
    }
}

@media screen and (max-width: 829px) {

    .serviceRealBackground
    {
        height: 75rem;
    }
}

@media screen and (max-width: 768px) {

   .serviceRealBackground
   {
    height: 115rem;
   }
}

@media screen and (max-width: 750px) {

    .dogPhoto
    {
        margin-top: 65rem;
    }


    .footerText,.footerTextTwo
    {
        padding-left: 0;
        text-align: center;
        margin-top: 0rem;
        
    }
    
   
    .footerMargin
    {
        padding-top: 0rem;
    }

    .andrewDog,.wishlistImg
    {
        padding-top: 50rem;
    }

    
}

@media screen and (max-width: 540px) {

    .scheduleBackground
    {
        height: 165rem;
    }

    .serviceBox
    {
        height: 20rem;
    }

    .serviceNewBackground
    {
        height: 130rem;
        padding-top: 5rem;
    }

    .serviceBox:last-child
    {
        height: 10rem;
    }

    /* Home box*/

    .HomeBox:nth-child(2)
    {
        height: 70rem;
    }
 
    .HomeBox:nth-child(3)
    {
        height: 70rem;
    }


    .HomeBox:first-child
    {
        height: 30rem;
    }

    .HomeContainer
    {
        height: 200rem;
    }

    


    .FooterBox:nth-child(2)
    {
        height: 30rem;
    }

    .footerBackground
    {
        height: 95rem;
    }

    .HomeBoxImage
    {
        height: 30rem;
    }

    .HomeWrapper
    {
        grid-template-rows: 70rem 30rem 70rem 30rem;
    }

    .HomeBox:first-child, .HomeBox:last-child
    {
        height: 30rem;
        
    }
    
}

@media screen and (max-width: 485px) {

    .HomeBoxTitleOne, .HomeBoxTitleTwo
    {
        padding-top: 5rem;
    }

    .dogPhoto {
        margin-top: 80rem;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }


    .andrewDog,.wishlistImg
    {
        padding-top: 70rem;
    }

    .wishlistImg
    {
        padding-top: 50rem;
    }

    .adjustBorder
    {
        margin-top : 5rem;
    }

    .CoverWatermark
    {
        margin-top:28.8rem;
    }

    .aboutBackground
    {
        height: 215rem;
    }

    .EmployeePhoto
    {
        width: 100%;
        
    }

    .PhotoOf
    {
        width: 100%;
        
    }

}

@media screen and (max-width: 440px)
{
    .PhotoResize {
            width: 350px;
            height: 275px;
        }

    .PhotoBackground
    {
        height: 880rem;
    }
}

@media screen and (max-width: 400px)
{
    .serviceRealTextAlt,.aboutText,.scheduleText
    {
        padding-left: 10px;
        padding-right: 10px;
    }

    .backgroundText
    {
        font-size: 2.4rem;
        margin-top: 15rem;
    }

    .backgroundVid
    {
        height: 50rem;
    }

    .HomeBoxTextOne, .HomeBoxTextTwo
    {
        padding-right: 10%;
        padding-left: 10%;
        text-align: center;
    }

    .HomeBoxTitleOne, .HomeBoxTitleTwo
    {
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
    }

    .aboutButton,.HomePhotoButton
    {
        display: flex;
        margin: 3rem auto;
        text-align: center;
        align-items: center;
        justify-content: center;
        
    }

    
    
    
}

@media screen and (max-width: 384px)
{
    .serviceBox
    {
        height: 24rem;
    }

    .serviceNewBackground
    {
        height: 150rem;
        padding-top: 5rem;
    }

        .PhotoResize {
            width: 300px;
            height: 225px;
        }

        .PhotoBackground
        {
            height: 750rem;
        }

        .HomeBoxTitleOne,.HomeBoxTitleTwo
        {
            font-size: 2.4rem;
        }

        .serviceBoxDescription
        {
            font-size: 0.95rem;
        }


}

@media screen and (max-width: 360px)
{
    .serviceBox
    {
        height: 28rem;
    }

    .serviceNewBackground
    {
        height: 163rem;
    }
}