* { margin : 0;
    font-family: DefaultFont;}

@font-face {
  font-family: DefaultFont;
  src: url(fonts/Jost-Medium.ttf);
}

@font-face {
  font-family: italicFont;
  src: url(fonts/Jost-BlackItalic.ttf);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0rem;
  padding-left: 3rem;
  padding-right: 10rem;
  padding-bottom: 0rem;
  position: relative;
  /* Added position: relative */
  z-index: 1;
  /* Added z-index: 1 */
}

.logo {
  width: 10rem;
  /* Adjust the width of the logo as needed */
  background-image: url(./images/bubspups.png);
  cursor: pointer;
}

.toggle {
  display: none;
}

.toggle-label {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  cursor: pointer;
  z-index: 2;
}

.toggle-label span {
  background-color: white;
  width: 24px;
  height: 2px;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  
}

.nav-item {
  margin-left: 2rem;
  /* Adjust the spacing between tabs as needed */
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .logo img {
    width: 120px;
    /* Adjust the width of the logo for mobile devices */
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 90%;
    left: 0%;
    width: 100%;
    background-color: #3A1F89;
    padding: 10px;
    z-index: 99999;
    /* Added z-index: 2 */
  }

  .toggle-label {
    display: flex;
  }

  .toggle:checked~.nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .toggle:checked~.nav-links .nav-item {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.slogan
{
  color: white;
  position: absolute;
  font-size: 0.75rem;
  margin-top: -3rem;
  font-family: italicFont;
}

@media only screen and (max-width: 400px)
 {
  .toggle-label
  {
    padding-left: 70%;
  }
 }